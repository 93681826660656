<template>
    <table :style="CSS" v-loading="loading" class="table-fixed-head">
        <thead>
          <tr>
            <th :style="styleHeader(CSSHeaderTable, item.cssHeader)" v-for="(item, index) in headers" :key="index">{{item.text}}</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="(record, index) in data" :key="index">
              <td v-for="(item, index) in headers" :key="index">
                <component
                  :is="item.type"
                  :css="item.css"
                  :scopeRow="record"
                  @click.native="openComponent(item, record)"
                  :value="record[item.value]"
                  :fixedNumber="item.fixedNumber"
                  :open-card="item.card || item.requestRegistry || {}"
                  :htmlTemplate="item.htmlTemplate"
                  :extended="item.extended"
                ></component>
              </td>
            </tr>
        </tbody>
        <slot></slot>
    </table>
</template>

<script>
import stringField from '@/components/Registry/Columns/string_field'
import booleanField from '@/components/Registry/Columns/boolean_field'
import dateField from '@/components/Registry/Columns/date_field'
import datetimeField from '@/components/Registry/Columns/datetime_field'
import floatField from '@/components/Registry/Columns/float_field'
import integerField from '@/components/Registry/Columns/integer_field'
import textField from '@/components/Registry/Columns/text_field'
import xrefField from '@/components/Registry/Columns/xref_field'
import xrefMultiField from '@/components/Registry/Columns/xref_multi_field'
import xrefOuterField from '@/components/Registry/Columns/xref_outer_field'
import fileField from '@/components/Registry/Columns/file_field'
import timeField from '@/components/Registry/Columns/time_field'
import indicatorField from '@/components/Registry/Columns/indicator_field'
import progressField from '@/components/Registry/Columns/progress_field'
import monthField from '@/components/Registry/Columns/month_field'
import htmlField from '@/components/Registry/Columns/html_field'

import mixin from '../mixins'
import clickTableMixin from '@/components/Registry/Columns/click_table_mixin'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
export default {
  name: 'a-list',
  mixins: [mixin, clickTableMixin, openCardMixin],
  components: {
    stringField,
    booleanField,
    dateField,
    datetimeField,
    floatField,
    integerField,
    textField,
    xrefField,
    xrefMultiField,
    xrefOuterField,
    fileField,
    timeField,
    indicatorField,
    progressField,
    monthField,
    htmlField
  },
  props: {
    name: {
      type: String,
      description: 'Атрибут'
    },
    keyField: {
      type: String,
      description: 'Поле для атрибута'
    },
    CSSHeaderTable: {
      type: String,
      description: 'CSS стили шапки таблицы'
    },
    // source: {
    //   type: Object,
    //   editor: 'Source',
    //   default: () => {
    //     return {
    //       type: null,
    //       entityId: null
    //     }
    //   }
    // },
    list: {
      type: Object,
      editor: 'List',
      default: () => {
        return {
          type: null,
          extendObjectId: null,
          requestsId: null
        }
      }
    },
    headers: {
      type: Array,
      editor: 'RegistryHeaders',
      description: 'Столбцы'
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      data: []
    }
  },
  watch: {
    dataFilters (val, oldVal) {
      // fix multi loadData
      if (this.list.type === 'requests') {
        if (val.length || oldVal.length) {
          this.loadData()
        }
        return val
      }
      this.loadData()
    }
  },
  mounted () {
    setTimeout(() => {
      if (!this.loading) {
        this.loadData()
      }
    }, 700)
  },
  methods: {
    async loadData () {
      if (this.list.type === 'extended_object' && this.list.extendObjectId) {
        this.loading = true
        try {
          let data = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${this.list.extendObjectId}`, this.dataFilters, { hideNotification: true })
          this.data = data.data
          if (this.name && this.keyField) {
            this.$emit('input', data.data.map((item) => item[this.keyField]))
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
      if (this.list.type === 'requests' && this.list.requestsId) {
        this.data = await this.getRequests(this.list.requestsId)
      }
      if (this.name) {
        this.$set(this.getModel(), `${this.name}_count`, this.data.length)
      }
    },
    async getRequests (id) {
      let response = await this.$http.get(`${this.$config.api}/datawarehouseservice/query/${id}?${this.dataFilters.join('&')}`)
      return response.data
    },
    async requestOpenCard (openCard, record) {
      let cardId, registryId, recordId
      registryId = record[openCard.requestRegistry]
      recordId = record[openCard.requestRecord]
      cardId = record[openCard.requestCard] || null
      if (!registryId || !recordId) {
        console.warn(`registryId: ${registryId}, recordId: ${recordId}`)
        return
      }
      if (!cardId) {
        cardId = await this.getCardId(registryId, recordId)
      }
      this.openRegistryCard({
        registryId: registryId,
        cardId: cardId.id || cardId,
        cardName: cardId.name || '',
        recordId: recordId
      })
    },
    openComponent (openCard, record) {
      console.log(openCard)
      if (!openCard.clickType || openCard.clickType === 'none') return false
      if (this.list.type === 'requests') {
        this.requestOpenCard(openCard, record)
      }
      if (openCard.clickType === 'open_card') {
        this.openCard(openCard.card, record)
      }
    },
    styleHeader (css, item) {
      return `${css} ${item}`
    }
  },
  computed: {
    dataFilters () {
      if (this.list.type === 'requests') {
        let filtersRequests = []
        if (this.filters) {
          this.filters.forEach((item) => {
            let type = `=`
            // if (item.isXref) {
            //   type = `eqx`
            // }
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filtersRequests.push(`${item.alias}${type}${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filtersRequests.push(`${item.alias}${type}${item.attribute}`)
            } else if (item.type === 'current_user' && item.alias) {
              filtersRequests.push(`${item.alias}${type}${this.$store.getters['Authorization/userId']}`)
            }
          })
        }
        return filtersRequests
      }
      let filters = {
        'where': {
          'and': []
        }
      }
      let isset = false
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = item.isXref ? 'equals_any' : 'eq'
          let object = {}
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              object[type] = {}
              object[type][item.alias] = this.getModel()[item.attribute]
              filters.where.and.push(object)
              isset = true
              // filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            object[type] = {}
            object[type][item.alias] = item.attribute
            // filters.push(`${item.alias}=${item.attribute}`)
            filters.where.and.push(object)
            isset = true
          } else if (item.type === 'current_user' && item.alias) {
            object[type] = {}
            object[type][item.alias] = this.$store.getters['Authorization/userId']
            filters.where.and.push(object)
            isset = true
          }
        })
      }
      return isset ? filters : null
    }
  }
}
</script>

<style scoped>
    th {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #7D7D7D;
    }
    td {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;

        text-align: center;

        color: #2C2D35;
    }
    th, td {
        /*opacity: 0.2;*/
        border-bottom: 1px solid rgba(124, 126, 128, 0.2);
        padding: 10px;
        width: 100px;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    thead th {
      position: sticky;
      top: 0;
      /* background: white; */
    }
    .table-fixed-head tbody tr:nth-child(even) {
      background-color: rgb(250 250 250);
    }
</style>
