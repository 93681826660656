export default {
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    }
  },
  methods: {
    async getCardId (registryId, recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async openCard (cardData, recordByList = null) {
      if (!cardData.registryId || !cardData.type) {
        console.warn('wrong parameters', cardData)
        return false
      }
      const readOnly = cardData.type === 'read'
      let recordId = null
      let card = {}
      let initialData = {}
      cardData.cardId
        ? card = { id: cardData.cardId }
        : card = await this.getCardId(cardData.registryId, recordId)

      let defaults = this.getDefaultsForCard(cardData)
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (cardData.type === 'update' || cardData.type === 'read') {
        console.log(`type: ${cardData.type}`)
        if (cardData.constantRecordId) {
          recordId = cardData.constantRecordId
        } else if (cardData.fieldId) {
          if (recordByList) {
            recordId = recordByList[cardData.fieldId]
          } else {
            recordId = this.getModel()[cardData.fieldId]
          }
        }
        if (!recordId) {
          console.warn(`recordId doesn't set = ${cardData.fieldId}, constant = ${cardData.constantRecordId}`)
          return false
        }
        if (!cardData.cardId) {
          card = await this.getCardId(cardData.registryId, recordId)
          console.log(`cardId doesn't set. card by states`, card)
        }
        if (cardData.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${cardData.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', cardData)
            return false
          }

          frame.openCard({
            cardId: cardData.cardId,
            registryId: cardData.registryId,
            recordId: recordId,
            readOnly: readOnly
          })
        } else if (cardData.isWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (cardData.windowWidth) {
            customClass += `dashboard_window_width_${cardData.windowWidth}`
          }
          let me = this
          this.$msgbox({
            title: cardData.windowTitle,
            customClass: customClass,
            message: h('registry-card', { props: {
              cardId: card.id,
              registryId: cardData.registryId,
              readonly: readOnly,
              recordId: recordId,
              initialData: initialData
            },
            on: {
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid() }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else {
          console.log('openRegistryCard', card)
          this.openRegistryCard({
            registryId: cardData.registryId,
            cardId: card.id,
            cardName: '',
            recordId: recordId,
            initialData: initialData,
            registry: {
              readonly: readOnly,
              addRecord: () => {},
              updateRecord: () => {}
            }
          })
        }
      }
    },
    getDefaultsForCard (cardData) {
      let defaults = []
      if (cardData.defaults) {
        cardData.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }

      return defaults
    }
  }
}
